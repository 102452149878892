<template>
  <div class="bg-white rounded-5 p-25">
    <div class="flex items-start justify-between mb-25">
      <div class="flex items-center">
        <AppIcon
          name="families"
          size="30"
          class="text-blue-ap-100 mr-20"
        />
        <p class="text-2xl">Families</p>
      </div>

      <div class="flex items-end">
        <AppButton 
          type="primary" 
          plain 
          class="px-30 mr-15" 
          :disabled="!familiesList.length"
          @click="onExport"
        >
          Export in CSV
        </AppButton>

        <AppInput
          :value="searchValue"
          label="Search by Last Name"
          name="search"
          class="max-w-250"
          @input="debounceSearch"
        />
      </div>
    </div>

    <div>
      <AppTable
        :dataset="familiesList"
        :headers="tableHeaders"
        :loading="vuex.isLoading"
        class="goals-table"
        rowHeight="50px"
        type="admin"
        theme="primary"
        show-actions
        sort-front-side
      >
        <!-- R O W S -->
        <template #lastSeenDate="{row}">
          <div v-if="row.lastSeenDate" class="flex flex-col">
            <p>
              {{ $filters.date(row.lastSeenDate, 'MM/DD/YYYY') }} 
            </p> 
            <p class="text-xs">
              {{ $filters.date(row.lastSeenDate, 'hh:mm a') }}
            </p>
          </div>
          <span v-else>-</span>
        </template>

        <template #phoneNumber="{row}">
          {{ row.phoneNumber ? formatStringPhoneNumber(row.phoneNumber) : '-' }}
        </template>

        <template #charityTrackingId="{row}">
          <AppTruncatedTooltip 
            v-if="row.charityTrackingId"
            :content="row.charityTrackingId" 
          />
          <span v-else>-</span>
        </template>

        <!-- A C T I O N S -->
        <template #actions="{ row, handleHidePopper }">
          <ul class="actions-wrapper py-10">
            <li
              v-for="(action, i) in actions"
              :key="i"
              class="action flex items-center cursor-pointer py-7 px-10"
              :class="action.customClass"
              @click="action.handler(row, handleHidePopper)"
            >
              <AppIcon
                :name="action.icon"
                size="12"
                class="mr-10"
              />
              <span>{{ action.title }}</span>
            </li>
          </ul>
        </template>
      </AppTable>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, computed, ref, onBeforeMount } from 'vue';
  import { useToast } from "vue-toastification";
  import { debounce } from 'lodash';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { familiesService } from '@/services';
  import { TIndexedObject, IFamilyData, IFamilyAttributes, IFamilyRes } from '@/types';
  import { formatStringPhoneNumber, downloadHelper } from '@/core/helper-functions';

  export default defineComponent({
    name: 'FamiliesList',

    components: { AppIcon, AppTable, AppTruncatedTooltip, AppInput, AppButton },

    setup() {
      const toast = useToast();
      const state = reactive({
        familiesList: [] as Array<IFamilyAttributes>,
        familiesListCopy: [] as Array<IFamilyAttributes>,
        activeTab: { value: 'predefined', label: 'Active' } as TIndexedObject,
      });
      const searchValue = ref<string>('');

      const { familiesList, familiesListCopy } = toRefs(state);
      const tableHeaders = computed(() => [
        { property: 'firstName', label: 'First Name', minWidth: 160, sortable: true },
        { property: 'lastName', label: 'Last Name', minWidth: 180, sortable: true },
        { property: 'email', label: 'Email', minWidth: 280, sortable: true },
        { property: 'phoneNumber', label: 'Phone number', minWidth: 200, sortable: true },
        { property: 'charityTrackingId', label: 'User ID', minWidth: 180, sortable: true },
        { property: 'lastSeenDate', label: 'Last seen date', minWidth: 180, sortable: true },
      ]);

      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: handleUpdate, customClass: ' text-dark-fp-20' }
      ]);

      const debounceSearch = debounce(handleSearch, 400);

      function handleSearch (e: InputEvent) {
        const target = e.target as HTMLInputElement;

        familiesList.value = familiesListCopy.value.filter((el: IFamilyAttributes) => {
          return el.lastName.toLowerCase().indexOf(target.value.toLowerCase()) > -1; 
        });
      }

      function handleUpdate({ id }: IFamilyAttributes) {
        router.push({ name: routesNames.adminUpdateFamily, query: { id } });
      }

      function mapFamilies(families: IFamilyRes) {

        return families.data.map(
          (el: IFamilyData) => {
            const {
              email,
              firstName,
              lastName,
              phoneNumber,
              charityTrackingId,
              organisationIdentifier
            } = el.details.attributes;
            return ({
              id: el.id,
              email,
              firstName,
              lastName,
              phoneNumber,
              charityTrackingId,
              lastSeenDate: el.lastSeenDate,
              organisationIdentifier
            });
          }
        );
      }

      async function fetchFamilies() {
        vuex.setLoading(true);

        return familiesService.fetchFamilies({ params: { } })
          .then((res: IFamilyRes) => {
            familiesList.value = mapFamilies(res); 
            familiesListCopy.value = [ ...familiesList.value ]; 
            return res;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return familiesService.downloadFamilies()
          .then((res: any) => {
            downloadHelper(res, 'Families', 'csv');
          } )
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(async () => {
        await fetchFamilies();
      });

      return {
        routesNames,
        router,
        tableHeaders,
        actions,
        familiesList,
        vuex,
        searchValue,

        onExport,
        handleUpdate,
        formatStringPhoneNumber,
        debounceSearch
      };
    }

  });
</script>

<style lang="scss" scoped>
  .goals-table {
    max-height: 450px;
  }
</style>